import React, { useState } from 'react';

const testimonials = [
  {
    id: 1,
    quote: "AeonCrop has revolutionized our farm's operations and profitability.",
    author: "John Doe",
    role: "Farmer"
  },
  {
    id: 2,
    quote: "The predictive analytics have helped us make better planting decisions.",
    author: "Jane Smith",
    role: "Agricultural Manager"
  },
  {
    id: 3,
    quote: "Blockchain transparency has given us confidence in our transactions.",
    author: "Mike Johnson",
    role: "Farm Owner"
  }
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-heading font-bold text-primary text-center mb-16">Success Stories</h2>
        <div className="max-w-4xl mx-auto">
          <div className="relative bg-background rounded shadow-md hover:shadow-lg transition-shadow p-8 md:p-12">
            <button 
              onClick={prevTestimonial}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-6 bg-white rounded-full p-2 shadow-md hover:bg-background transition-colors"
            >
              ←
            </button>
            <div className="text-center">
              <p className="text-xl md:text-2xl text-text italic mb-6">
                "{testimonials[currentIndex].quote}"
              </p>
              <div className="flex flex-col items-center">
                <p className="font-heading font-bold text-primary">
                  {testimonials[currentIndex].author}
                </p>
                <p className="text-text opacity-75">
                  {testimonials[currentIndex].role}
                </p>
              </div>
            </div>
            <button 
              onClick={nextTestimonial}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-6 bg-white rounded-full p-2 shadow-md hover:bg-background transition-colors"
            >
              →
            </button>
          </div>
          <div className="flex justify-center mt-8 space-x-2">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`w-3 h-3 rounded-full transition-colors ${
                  index === currentIndex ? 'bg-secondary' : 'bg-gray-300'
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials; 