import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBrain, FaLock, FaSatelliteDish, FaPhone, FaEnvelope, FaMapMarkerAlt, FaBuilding, FaIdCard, FaLeaf, FaSeedling } from 'react-icons/fa';
import Testimonials from './Testimonials';
import HowItWorks from './HowItWorks';

const LandingPage = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const headerOffset = 80; // Height of fixed header
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="min-h-screen bg-background font-body">
      {/* Header */}
      <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled ? 'bg-white shadow-md' : 'bg-transparent'
      }`}>
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-20">
            {/* Logo */}
            <div className="flex items-center space-x-2">
              <div className="flex items-center">
                <FaSeedling className={`text-3xl ${
                  isScrolled ? 'text-secondary' : 'text-accent'
                } transition-colors`} />
                <FaLeaf className={`text-xl -ml-1 ${
                  isScrolled ? 'text-primary' : 'text-white'
                } transition-colors`} />
              </div>
              <Link to="/" className={`text-2xl font-heading font-bold ${
                isScrolled ? 'text-primary' : 'text-white'
              }`}>
                AeonCrop
              </Link>
            </div>

            {/* Navigation */}
            <nav className="hidden md:flex items-center space-x-8">
              <button
                onClick={() => scrollToSection('about')}
                className={`font-heading hover:text-accent transition-colors ${
                  isScrolled ? 'text-text' : 'text-white'
                }`}
              >
                About
              </button>
              <button
                onClick={() => scrollToSection('how-it-works')}
                className={`font-heading hover:text-accent transition-colors ${
                  isScrolled ? 'text-text' : 'text-white'
                }`}
              >
                How It Works
              </button>
              <button
                onClick={() => scrollToSection('features')}
                className={`font-heading hover:text-accent transition-colors ${
                  isScrolled ? 'text-text' : 'text-white'
                }`}
              >
                Features
              </button>
              <button
                onClick={() => scrollToSection('testimonials')}
                className={`font-heading hover:text-accent transition-colors ${
                  isScrolled ? 'text-text' : 'text-white'
                }`}
              >
                Success Stories
              </button>
              <button
                onClick={() => scrollToSection('contact')}
                className={`font-heading hover:text-accent transition-colors ${
                  isScrolled ? 'text-text' : 'text-white'
                }`}
              >
                Contact
              </button>
            </nav>
          </div>
        </div>
      </header>

      {/* Hero Section */}
      <section className="relative bg-primary text-white pt-32">
        <div className="container mx-auto px-4 py-20">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl lg:text-5xl font-heading font-bold mb-6">
              Empowering Farmers with Predictive Intelligence and Blockchain Transparency
            </h1>
            <p className="text-xl opacity-90">
              Revolutionizing agriculture with AI and blockchain for stable crop pricing.
            </p>
          </div>
        </div>
      </section>

      {/* Introduction Section */}
      <section id="about" className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl font-heading font-bold text-primary text-center mb-12">
              Transforming Agriculture with Technology
            </h2>
            
            <div className="grid md:grid-cols-2 gap-12">
              {/* Left Column */}
              <div className="space-y-6">
                <div>
                  <h3 className="text-xl font-heading font-bold text-primary mb-3">
                    Revolutionizing Farming Practices
                  </h3>
                  <p className="text-text">
                    In an era where technology reshapes agriculture, AeonCrop stands at the forefront, 
                    offering farmers a revolutionary tool to navigate the complexities of crop pricing. 
                    Our platform seamlessly integrates artificial intelligence with blockchain technology 
                    to deliver accurate and transparent predictions.
                  </p>
                </div>

                <div>
                  <h3 className="text-xl font-heading font-bold text-primary mb-3">
                    Data-Driven Decision Making
                  </h3>
                  <p className="text-text">
                    By leveraging advanced analytics and real-time market data, we empower farmers 
                    to make informed decisions about planting, harvesting, and selling their crops. 
                    Our AI-powered insights help optimize farming operations and maximize profitability.
                  </p>
                </div>
              </div>

              {/* Right Column */}
              <div className="space-y-6">
                <div>
                  <h3 className="text-xl font-heading font-bold text-primary mb-3">
                    Blockchain-Powered Transparency
                  </h3>
                  <p className="text-text">
                    Through blockchain technology, we ensure complete transparency in agricultural 
                    transactions. Every data point, from weather forecasts to market prices, is 
                    securely recorded and verifiable, building trust among all stakeholders in 
                    the agricultural supply chain.
                  </p>
                </div>

                <div>
                  <h3 className="text-xl font-heading font-bold text-primary mb-3">
                    Sustainable Agriculture Future
                  </h3>
                  <p className="text-text">
                    Our commitment extends beyond profitability to promoting sustainable farming 
                    practices. By providing accurate weather predictions and market insights, 
                    we help farmers make environmentally conscious decisions while maintaining 
                    profitable operations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section id="how-it-works">
        <HowItWorks />
      </section>

      {/* Features Section */}
      <section id="features" className="py-20 bg-background">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-heading font-bold text-primary text-center mb-16">Our Features</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {/* Predictive Intelligence */}
            <div className="bg-white p-8 rounded shadow-md hover:shadow-lg transition-shadow">
              <div className="text-secondary text-4xl mb-4">
                <FaBrain />
              </div>
              <h3 className="text-xl font-heading font-bold text-primary mb-4">Predictive Intelligence</h3>
              <p className="text-text mb-4">
                Leverage AI to forecast crop prices accurately.
              </p>
              <ul className="space-y-2">
                <li className="flex items-center">
                  <span className="text-secondary mr-2">✓</span>
                  <span className="text-text">Accurate price prediction</span>
                </li>
                <li className="flex items-center">
                  <span className="text-secondary mr-2">✓</span>
                  <span className="text-text">Weather-resilient planning</span>
                </li>
                <li className="flex items-center">
                  <span className="text-secondary mr-2">✓</span>
                  <span className="text-text">Data-driven decision making</span>
                </li>
              </ul>
            </div>

            {/* Blockchain Transparency */}
            <div className="bg-white p-8 rounded shadow-md hover:shadow-lg transition-shadow">
              <div className="text-secondary text-4xl mb-4">
                <FaLock />
              </div>
              <h3 className="text-xl font-heading font-bold text-primary mb-4">Blockchain Transparency</h3>
              <p className="text-text mb-4">
                Ensure every transaction is secure and traceable.
              </p>
              <ul className="space-y-2">
                <li className="flex items-center">
                  <span className="text-secondary mr-2">✓</span>
                  <span className="text-text">Tamper-proof records</span>
                </li>
                <li className="flex items-center">
                  <span className="text-secondary mr-2">✓</span>
                  <span className="text-text">Enhanced trust</span>
                </li>
                <li className="flex items-center">
                  <span className="text-secondary mr-2">✓</span>
                  <span className="text-text">Secure transactions</span>
                </li>
              </ul>
            </div>

            {/* Real-time Data Access */}
            <div className="bg-white p-8 rounded shadow-md hover:shadow-lg transition-shadow">
              <div className="text-secondary text-4xl mb-4">
                <FaSatelliteDish />
              </div>
              <h3 className="text-xl font-heading font-bold text-primary mb-4">Real-time Data Access</h3>
              <p className="text-text mb-4">
                Utilize satellite and market data for optimal farming decisions.
              </p>
              <ul className="space-y-2">
                <li className="flex items-center">
                  <span className="text-secondary mr-2">✓</span>
                  <span className="text-text">Global market insights</span>
                </li>
                <li className="flex items-center">
                  <span className="text-secondary mr-2">✓</span>
                  <span className="text-text">Weather forecasts</span>
                </li>
                <li className="flex items-center">
                  <span className="text-secondary mr-2">✓</span>
                  <span className="text-text">Satellite imagery</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section id="testimonials">
        <Testimonials />
      </section>

      {/* Footer with Contact Information */}
      <section id="contact">
        <footer className="bg-primary text-white py-12">
          <div className="container mx-auto px-4">
            <div className="grid md:grid-cols-4 gap-8 mb-8">
              <div>
                <h4 className="text-lg font-heading font-bold mb-4">Company</h4>
                <ul className="space-y-2">
                  <li><Link to="/about" className="hover:text-accent transition-colors">About Us</Link></li>
                  <li><Link to="/careers" className="hover:text-accent transition-colors">Careers</Link></li>
                </ul>
              </div>
              <div>
                <h4 className="text-lg font-heading font-bold mb-4">Resources</h4>
                <ul className="space-y-2">
                  <li><Link to="/blog" className="hover:text-accent transition-colors">Blog</Link></li>
                  <li><Link to="/help" className="hover:text-accent transition-colors">Help Center</Link></li>
                </ul>
              </div>
              <div>
                <h4 className="text-lg font-heading font-bold mb-4">Legal</h4>
                <ul className="space-y-2">
                  <li><Link to="/privacy" className="hover:text-accent transition-colors">Privacy Policy</Link></li>
                  <li><Link to="/terms" className="hover:text-accent transition-colors">Terms of Use</Link></li>
                </ul>
              </div>
              <div>
                <h4 className="text-lg font-heading font-bold mb-4">Connect</h4>
                <div className="flex space-x-4">
                  <a href="https://twitter.com" className="hover:text-accent transition-colors">
                    Twitter
                  </a>
                  <a href="https://linkedin.com" className="hover:text-accent transition-colors">
                    LinkedIn
                  </a>
                </div>
              </div>
            </div>

            {/* Contact Information */}
            <div className="border-t border-white/10 pt-8">
              <div className="grid md:grid-cols-2 gap-8">
                <div className="space-y-4">
                  <div className="flex items-center space-x-3">
                    <FaBuilding className="text-accent" />
                    <div>
                      <p className="font-heading font-bold">Company</p>
                      <p className="opacity-80">APLICACIONES FOTOVOLTAICAS AEO Y ASOCIADOS SL</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-3">
                    <FaIdCard className="text-accent" />
                    <div>
                      <p className="font-heading font-bold">NIF/CIF</p>
                      <p className="opacity-80">B85775302</p>
                    </div>
                  </div>
                  <div className="flex items-start space-x-3">
                    <FaMapMarkerAlt className="text-accent mt-1" />
                    <div>
                      <p className="font-heading font-bold">Address</p>
                      <p className="opacity-80">Calle del Primero de Mayo, 55, 28100 Alcobendas, Madrid, España</p>
                    </div>
                  </div>
                </div>
                <div className="space-y-4">
                  <div className="flex items-center space-x-3">
                    <FaPhone className="text-accent" />
                    <div>
                      <p className="font-heading font-bold">Phone</p>
                      <a href="tel:+34611441289" className="opacity-80 hover:text-accent transition-colors">+34 611 441 289</a>
                    </div>
                  </div>
                  <div className="flex items-center space-x-3">
                    <FaEnvelope className="text-accent" />
                    <div>
                      <p className="font-heading font-bold">Email</p>
                      <a href="mailto:contact@aeoncrop.com" className="opacity-80 hover:text-accent transition-colors">contact@aeoncrop.com</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Copyright */}
            <div className="text-center mt-8 pt-8 border-t border-white/10">
              <p className="text-sm opacity-70">
                © {new Date().getFullYear()} AeonCrop. All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </section>
    </div>
  );
};

export default LandingPage; 