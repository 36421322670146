import React from 'react';

const HowItWorks = () => {
  return (
    <section className="py-20 bg-background">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-heading font-bold text-primary mb-6">How AeonCrop Works</h2>
            <p className="text-xl text-text">
              By accessing real-time data from satellites, comprehensive weather forecasts, 
              and global market analyses, farmers can plan their planting strategies and 
              selling decisions with confidence.
            </p>
          </div>

          <div className="space-y-8">
            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0 w-10 h-10 bg-secondary bg-opacity-10 rounded flex items-center justify-center">
                <span className="text-secondary font-heading font-bold">1</span>
              </div>
              <div>
                <h3 className="text-xl font-heading font-bold text-primary mb-2">Data Collection</h3>
                <p className="text-text">
                  Our system continuously collects data from multiple sources including 
                  satellite imagery, weather stations, and market databases.
                </p>
              </div>
            </div>

            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0 w-10 h-10 bg-secondary bg-opacity-10 rounded flex items-center justify-center">
                <span className="text-secondary font-heading font-bold">2</span>
              </div>
              <div>
                <h3 className="text-xl font-heading font-bold text-primary mb-2">AI Analysis</h3>
                <p className="text-text">
                  Advanced AI algorithms process and analyze the collected data to 
                  generate accurate predictions and insights.
                </p>
              </div>
            </div>

            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0 w-10 h-10 bg-secondary bg-opacity-10 rounded flex items-center justify-center">
                <span className="text-secondary font-heading font-bold">3</span>
              </div>
              <div>
                <h3 className="text-xl font-heading font-bold text-primary mb-2">Blockchain Integration</h3>
                <p className="text-text">
                  All transactions and data points are securely recorded on the 
                  blockchain, ensuring transparency and trust.
                </p>
              </div>
            </div>

            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0 w-10 h-10 bg-secondary bg-opacity-10 rounded flex items-center justify-center">
                <span className="text-secondary font-heading font-bold">4</span>
              </div>
              <div>
                <h3 className="text-xl font-heading font-bold text-primary mb-2">Actionable Insights</h3>
                <p className="text-text">
                  Farmers receive clear, actionable insights through our intuitive 
                  dashboard, helping them make informed decisions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks; 